<!--
// nuxt-ui/components/base/BaseVideoModal.vue
-->
<script setup lang="ts">
type Props = {
  modalId: string;
  videoId: string;
};

const props = defineProps<Props>();
const model = defineModel<boolean>({ required: true });
const clickOutside = event => {
  if (event.target.id === props.modalId) {
    model.value = false;
  }
};
</script>

<template>
  <div
    :id="modalId"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-800 bg-opacity-20"
    @click="e => clickOutside(e)"
  >
    <div class="relative w-full max-w-3xl overflow-hidden rounded-lg z-[55]">
      <!-- Modal content -->
      <div class="relative bg-gray-900 rounded-lg shadow z-[60]">
        <!-- Modal close btn -->
        <div class="absolute top-3 right-3">
          <button
            type="button"
            class="text-gray-400 bg-gray-800 hover:bg-primary hover:text-black rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            :data-modal-hide="modalId"
            @click="model = false"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <slot name="header"></slot>
        <div class="overflow-hidden rounded-lg md:min-h-[400px]">
          <iframe
            v-if="model"
            height="400"
            width="100%"
            :src="`https://www.youtube.com/embed/${videoId}?rel=0`"
            title="Video Player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
